<template>
    <div class="">
        <div class="flex justify-space-between">
            <div class="box self-flex-end"><h2 class="mv-0">Event</h2></div>
            <div class="box"><el-button @click="$onCommandParams('home')">BACK</el-button></div>
        </div>
        <el-divider class="mv-10 o-020"></el-divider>
          <div class="flex column gaps">
            <div class="box flex gaps">
              <el-input class="box grow" ref="search" v-model="search"></el-input>
              <div class="box center">
                <el-button size="small" @click="$onCommandParams('createEvent')">Create Event</el-button>
              </div>
            </div>
            <div class="box">
              <vue-scroll class="scroll-area-members flex" :settings="settings" :style="{height: scrollHeight + 'px'}">
                <div class="box grow">
                  <div v-for="c in filtered" :key="c.uid" class="flex column mr-20">
                    <div class="flex">
                      <div class="info box grow flex clickable"  @click="clickEvent(c.uid)">
                        <div class="name box grow flex column justify-center p-10">
                          <div class="fullname fs-18"><strong>{{c.name}}</strong></div>
                          <div class="fullname fs-14 info-text"><strong>{{c.contact.first}} {{c.contact.surname}}</strong></div>
                          <div class="fullname fs-14 secondary-text"><strong>{{dateFormat(c.date)}}</strong></div>
                          <div class="fullname fs-14 secondary-text"><strong>{{c.persons}} persons</strong></div>
                        </div>
                        <div class="box center rigth">
                          <div class="flex column gaps">
                            <div class="box fs-16"><span class="secondary-text">Cost:</span> <strong>{{c.cost}} AED</strong></div>
                            <div class="box fs-16"><span class="secondary-text">Prepaid:</span> <strong>{{c.prepaid}} AED</strong></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <el-divider class="mv-10 o-020"></el-divider>
                  </div>
                </div>

              </vue-scroll>
            </div>
          </div>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
  name: 'SaleEvents',

  props: {
    basket: {
      type: Array,
      default: function () {
        return []
      }
    }
  },

  data () {
    return {
      search: '',
      settings: {
        maxScrollbarLength: 60
      },
      fileList: [],
      form: {
        times: 1,
        cost: 2250,
        persons: 15,
        prepaid: 0,
        comment: ''
      }
    }
  },
  computed: {
    ...mapState({
      innerHeight: state => state.nav.innerHeight,
      events: state => state.event.events
    }),
    ...mapGetters([
      'lastRowId',
      'getEventById',
      'getCompanyByCategory'
    ]),
    currentBusketItem () {
      let row = this.basket.find(b => b.basketId === this.$route.params.id)
      return row !== undefined ? row : {
        name: 'Event',
        desc: [],
        times: 0,
        rate: 0
      }
    },
    filtered () {
      return this.events.filter(item => {
        return !item.isClose && (this.search.length < 2 ||
       (item.hasOwnProperty('name') && item.name.toLowerCase().indexOf(this.search.toLowerCase()) !== -1) ||
       (item.hasOwnProperty('contact') && item.contact.hasOwnProperty('first') && item.contact.first.toLowerCase().indexOf(this.search.toLowerCase()) !== -1) ||
       (item.hasOwnProperty('contact') && item.contact.hasOwnProperty('surname') && item.contact.surname.toLowerCase().indexOf(this.search.toLowerCase()) !== -1) ||
       (item.hasOwnProperty('contact') && item.contact.hasOwnProperty('phone') && item.contact.phone.toLowerCase().indexOf(this.search.toLowerCase()) !== -1) ||
       (item.hasOwnProperty('contact') && item.contact.hasOwnProperty('email') && item.contact.email.toLowerCase().indexOf(this.search.toLowerCase()) !== -1) ||
       (item.hasOwnProperty('comment') && item.comment.toLowerCase().indexOf(this.search.toLowerCase()) !== -1))
      })
    },
    scrollHeight () {
      return this.innerHeight - 395
    }
  },
  methods: {
    clickEvent (id) {
      let company = this.getCompanyByCategory['Events']
      let event = this.getEventById[id]
      this.$store.dispatch('createBasketItem', {
        category: 'Events',
        eventId: id,
        name: 'Event',
        desc: [event.name, 'mem:' + event.contact.first + ' ' + event.contact.surname],
        times: 1,
        rate: event.cost - event.prepaid,
        companyId: company._id,
        companyName: company.name
      }).then(() => {
        this.$onCommandParams('saleEvent', { id: this.lastRowId })
      })
    },
    dateFormat (d) {
      let today = new Date(d)
      let options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' }
      return today.toLocaleString('en-GB', options)
    }
  }
}
</script>
